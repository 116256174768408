.wrapper {
  text-align: center;
}

.loader {
  font-size: 24px;
  animation: move 4s ease-in-out infinite;
}

.text {
  font-size: 12px;
  animation: blinker 1s linear infinite;
}

@keyframes move {
  0% {
  transform: translateX(50%);
  }
  100% {
  transform: translateX(-50%);
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
