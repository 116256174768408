:root {
  --bg-color: rgba(244, 240, 241, 1);
  --text-main: rgba(128, 109, 113, 1);
  --text-light: rgba(194, 184, 187, 1);
  --cta-color: rgba(68, 97, 154, 1);
  --white: rgba(255, 255, 255, 1);
  --font-big: 24px;
  --font-normal: 16px;
  --font-small: 14px;
}

html, body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  color: var(--text-main);
}


.small {
  font-size: var(--font-small);
}

.center {
  text-align: center;
}

.PhoneInput {
  background-color: var(--white);
  padding: 8px 16px;
  margin: 28px auto;
  border-radius: 10px;
}

.PhoneInput .PhoneInputInput {
    padding: 0 6px;
    margin: 0;
    width: 240px;
  }

.PhoneInput.hasError {
    border: 1px solid red;
  }
