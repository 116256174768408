.profile {
  margin: auto;
  max-width: 360px;
  display: grid;
  grid-template-rows: auto 1fr auto;
  user-select: none;
  max-height: 100vh;
  height: -webkit-fill-available;
}

.user {
  display: grid;
  grid-template-columns: 96px auto auto auto;
  align-items: center;
  margin: 12px 0px;
}

.calendar {
  flex-grow: 1;
}

.username {
  grid-column: span 3;
  display: inline-flex;
  align-items: center;
  margin: 6px;
  font-size: 24px;
}

.count {
  margin-left: 8px;
}

.count span {
  font-weight: 700;
}
