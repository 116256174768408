.avatar {
  grid-row: 1 / span 2;
  width: 72px;
  height: 72px;
  border: 5px solid #FFFFFF;
  border-radius: 50%;
  font-size: 58px;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
}

.over {
  border-color: var(--cta-color);
}
