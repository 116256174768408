.day {
  min-height: 60px;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.4);
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.over {
  background-color: white;
}

.dropzone {
  min-height: 60px;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.date {
  font-size: 24px;
  text-align: center;
}

.year {
  position: absolute;
  background: black;
  color: white;
}

.month {
  position: absolute;
  background: var(--text-main);
  padding: 0px 6px;
  color: white;
}
