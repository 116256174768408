.button {
  all: unset;
  display: block;
  font-size: 18px;
  -webkit-text-fill-color: var(--cta-color);
  color: var(--cta-color);
  font-weight: bold;
  cursor: pointer;
}

button:hover {
  opacity: 0.8;
}

.button.cta {
  margin: 12px auto;
  padding: 12px 24px;
  border-radius: 24px;
  background-color: var(--cta-color);
  -webkit-text-fill-color: var(--white);
  color: var(--white);
  font-weight: bold;
}

.button.warning {
  background-color: red;
}

.button.naked {
  font-size: 14px;
}

.button.back {
  position: absolute;
  top: 28px;
  left: 24px;
  width: 58px;
  height: 36px;
}

.button.back::after {
  display: block;
  content: "";
  width: 16px;
  height: 16px;
  border-top: 3px solid var(--text-main);
  border-right: 3px solid var(--text-main);
  transform: rotate(-135deg);
}

.button.follow {
  border: 2px solid #FFFFFF;
  color: var(--text-main);
  -webkit-text-fill-color: var(--text-main);
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  padding: 2px 18px;
  margin: auto;
  height: 18px;
  text-align: center;
}

.button.follow-active {
  border: 2px solid #FFFFFF;
  background: var(--cta-color);
  color: var(--white);
  -webkit-text-fill-color: var(--white);
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  padding: 2px 18px;
  margin: auto;
  height: 18px;
  text-align: center;
}

.button.follow:hover {
  background: #FFFFFF;
}


.button.icon {
  margin: 6px;
  width: 44px;
  height: 44px;
  font-size: 30px;
  font-style: normal;
  text-align: center;
  cursor: pointer;
}
