main {
    margin: auto;
    height: 100vh;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.logo {
  width: 280px;
  margin-left: auto;
  margin-right: auto;
}
