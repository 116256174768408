.wrapper {
  width: 360px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
  position: relative;
  align-items: flex-end;
}

.load {
  margin: auto;
  width: 80px;
  padding: 18px 0 18px 0
}

.loader {
  grid-column: 1/-1;
  width: 100%;
}

.trigger {
  grid-column: 1/-1;
  height: 50px;
  width: 100%;
  margin-bottom: 5px;
}
