.toolbox {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto 1fr;
  gap: 1px;
  height: 80px;
  align-items: center;
  justify-items: center;
  padding-bottom: 32px;
}

.weekdays {
  padding: 8px 0px;
}

.trash {
  font-size: 28px;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.trashOver {
  transform: scale(1.5);
}

.picker {
  position: absolute;
  top: 0px;
  max-height: 100vh;
  height: -webkit-fill-available;
  max-width: 360px;
}

.show {
  display: block;
}

.hide {
  display: none;
}
