main {
    margin: auto;
    height: 100vh;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

input, textarea {
  all: unset;
  background-color: var(--white);
  border-radius: 10px;
  font-size: 24px;
  margin: 12px 0px;
  text-align: left;
}

input {
  padding: 8px 16px;
  width: 260px;
}

textarea {
  font-size: 18px;
  padding: 16px;
  min-height: 160px;
}

input.token {
  letter-spacing: 8px;
  text-align: center;
  margin: 12px auto;
}

.error {
  display: none;
  color: red;
  font-size: 12px;
}

.error.hasError {
  display: block;
}

.username {
  border-radius: 0 10px 10px 0;
  padding-left: 0;
}

.username.hasError {
  border-top: 2px solid red;
  border-right: 2px solid red;
  border-bottom: 2px solid red;
}


.arobase {
  font-size: 24px;
  background-color: var(--white);
  padding: 8px;
  padding-right: 2px;
  border-radius: 10px 0 0 10px;
}

.arobase.hasError {
  border-top: 2px solid red;
  border-left: 2px solid red;
  border-bottom: 2px solid red;
}
